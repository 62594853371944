<template>
  <div>
    <v-card>
      <v-card-title>
      <v-icon color="primary">
        mdi-bus-multiple
      </v-icon>
        <span class="pl-2">Autobusët</span>
        <v-spacer></v-spacer>
        <create-button @create="showBusDialog"></create-button>
        <activation-tool-tip model="buses"></activation-tool-tip>
      </v-card-title>
      <v-data-table
        item-key="id"
        :loading="isLoading"
        loading-text="Po ngarkohet... Ju lutemi prisni"
        :headers="headers"
        :items="buses"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Kërko"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item.driver="{ item }">
          <v-chip :color="getDriverAssignmentColor(item.driver)" dark @click="assignDriver(item)">
            {{ getDriver(item.driver) }}
          </v-chip>
        </template>
        <template v-slot:item.created_at="{ item }">
          <small>{{ item.created_at | moment("LL") }}</small> -
          <small class="text-muted">{{ item.created_at | moment("LT") }}</small>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon v-if="item.driver" small class="mr-2" @click="unAssignDriver(item)">
            mdi-account-off
          </v-icon>
          <v-icon v-else small class="mr-2" @click="assignDriver(item)">
            mdi-account-tie-hat
          </v-icon>
          <v-icon small class="mr-2" @click="editBus(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteBus(item, buses.indexOf(item))">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-row justify="center">
      <v-dialog
        v-model="busDialog"
        persistent
        max-width="900px"
      >
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">Të dhënat e autobusit</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="license"
                      :rules="licenseRules"
                      label="Targa*"
                      hint="Targa e autobusit"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="capacity"
                      :rules="capacityRules"
                      label="Kapaciteti*"
                      hint="numri i vendeve në autobus"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="3"
                  >
                    <v-text-field
                      v-model="price_factor"
                      :rules="priceFactorRules"
                      label="Faktori i Çmimeve*"
                      hint="faktori i çmimit të autobusit, p.sh. 1.5 do të thotë 50% më shumë se çmimi normal"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="busDialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="createBus"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
    <v-dialog v-if="selectedBus" v-model="driversDialog" max-width="390">
      <v-card>
        <v-card-title class="text-h5"> Zgjidhni drejtuesin për '{{ selectedBus.license}}' </v-card-title>

        <v-card-text>
          <v-list dense>
            <v-subheader>Drejtuesit</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(driver, i) in availableDrivers"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="driver.name" @click="assignDriverToBus(driver)"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-container style="height: 400px">
          <v-row
            v-show="loadingDrivers"
            class="fill-height"
            align-content="center"
            justify="center"
          >
            <v-col class="text-subtitle-1 text-center" cols="12">
             Ju lutem prisni...
            </v-col>
            <v-col cols="6">
              <v-progress-linear
                :active="loadingDrivers"
                color="primary"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="closeDriverDialog"
          >
            Mbylle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ActivationToolTip from "@/components/ActivationToolTip";
import CreateButton from "@/components/CreateButton";
import auth from '@/services/AuthService'
export default {
  components: {
    ActivationToolTip,
    CreateButton,
  },
  data() {
    return {
      buses: [],
      availableDrivers: [],
      isLoading: false,
      search: "",
      busDialog: false,
      driversDialog: false,
      loadingDrivers: false,
      valid: true,
      id: null,
      selectedBus: null,
      license: '',
      licenseRules: [
        v => !!v || 'License plate is required',
        v => (v && v.length <= 15) || 'Targat duhet të jenë më pak se 15 karaktere',
      ],
      capacity: '',
      capacityRules: [
        v => /^[0-9]+$/.test(v) || 'Kapaciteti nuk është i vlefshëm',
      ],
      priceFactor: '1',
      priceFactorRules: // price factor must be greater than 0 and can be a decimal number
        [
            v => /^[0-9]+(\.[0-9]+)?$/.test(v) || 'Faktori i çmimit nuk është i vlefshëm',
            v => v > 0 || 'Faktori i çmimit duhet të jetë më i madh se 0',
        ],
      headers: [
        { text: "ID", value: "id", align: "start", filterable: false },
        { text: "Licenca", value: "license" },
        { text: "Kapaciteti", value: "capacity" },
        { text: "Faktori i Çmimeve", value: "price_factor" },
        { text: "Shoferi", value: "driver" },
        { text: "Krijuar", value: "created_at" },
        { text: "Veprimet", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.loadBuses();
  },
  methods: {
    loadBuses() {
      this.isLoading = true;
      this.buses = [];
      axios
        .get(`/buses/all`)
        .then((response) => {
          this.buses = response.data;
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë marrjes së autobusëve",
            type: 'error'
          });
          console.log(error);
          auth.checkError(error.response.data.message, this.$router, this.$swal);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    validate () {
      return this.$refs.form.validate()
    },
    createBus() {
      if(this.validate())
      {
        this.isLoading = true;
        this.busDialog = false;
        axios
          .post(`/buses/create-edit`, {
            bus: {
              id: this.id,
              license: this.license,
              capacity: this.capacity,
              price_factor: this.price_factor,
            },
          })
          .then((response) => {
            this.loadBuses();
            this.$notify({
              title: "Success",
              text: this.id? "Autobusi u përditësua!" : "Autobusi u krijua!",
              type: 'success'
            });
            this.$swal("Sukses", "Autobusi " + (this.id? "përditësuar" : "krijuar") + " me sukses", "success");
          })
          .catch((error) => {
            this.$notify({
              title: "Gabim",
              text: "Gabim gjatë krijimit të autobusit",
              type: 'error'
            });
            console.log(error);
            this.$swal("Gabim", error.response.data.message, "error");
          })
          .then(() => {
            this.isLoading = false;
          });
      }
    },
    showBusDialog() {
      this.license = '';
      this.capacity = '';
      this.price_factor = '1';
      this.id = null;
      this.busDialog = true;
    },
    editBus(bus) {
      this.id = bus.id;
      this.license = bus.license;
      this.capacity = bus.capacity;
        this.price_factor = bus.price_factor;
      this.busDialog = true;
    },
    deleteBus(bus, index) {
      this.$swal
        .fire({
          title: "Fshi autobusin",
          text: "A jeni i sigurt për të fshirë autobusin ' " + bus.license + " ' ? Nuk do të mund ta kthesh këtë!",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Po, fshijeni!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteBusServer(bus.id, index);
          }
        });
    },
    deleteBusServer(bus_id, index) {
      axios
        .delete(`/buses/${bus_id}`)
        .then((response) => {
          this.buses.splice(index, 1);
          this.$notify({
            title: "Sukses",
            text: "Autobusi u fshi!",
            type: "success",
          });
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë fshirjes së autobusëve",
            type: 'error'
          });
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          //this.isDeleting = false;
        });
    },
    getDriverAssignmentColor(driver) {
      if (driver) return "success";
      else return "error";
    },
    getDriver(driver) {
      if (driver) return driver.name;
      else return "none";
    },
    assignDriver(item) {
      this.selectedBus = item;
      this.driversDialog = true;
      this.loadAvailableDrivers()
    },
    loadAvailableDrivers() {
      this.loadingDrivers = true;
      this.availableDrivers = [];
      axios
        .get('/buses/available-drivers')
        .then((response) => {
          this.availableDrivers = response.data;
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë marrjes së drejtuesve",
            type: 'error'
          });
          console.log(error);
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          this.loadingDrivers = false;
        });
    },
    assignDriverToBus(driver) {
      this.loadingDrivers = true;
      axios
        .post(`/buses/assign-driver`, {
          bus_id: this.selectedBus.id,
          driver_id: driver.id,
        })
        .then((response) => {
          this.loadBuses();
          this.$notify({
            title: "Sukses",
            text: "Shoferi i caktuar në autobus!",
            type: 'success'
          });
          this.$swal("Sukses", "Shoferi u caktua me sukses në autobus", "success");
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë caktimit të shoferit në autobus",
            type: 'error'
          });
          console.log(error);
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          this.loadingDrivers = false;
          this.closeDriverDialog();
        });
    },
    unAssignDriver(item)
    {
      this.$swal
        .fire({
          title: "Hiq caktimin e shoferit nga autobusi",
          text: "Jeni i sigurt për të hequr caktimin e shoferit ' " + item.driver.name + " ' nga autobusi '" + item.license + "' ? Nuk do të mund ta kthesh këtë!",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Po, fshijeni!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.unAssignDriverFromBus(item);
          }
        });
    },
    unAssignDriverFromBus(item) {
      this.isLoading = true;
      axios
        .post(`/buses/unassign-driver`, {
          bus_id: item.id,
        })
        .then((response) => {
          this.loadBuses();
          this.$notify({
            title: "Sukses",
            text: "Shoferi u hoq nga autobusi!",
            type: 'success'
          });
          this.$swal("Sukses", "Shoferi u hoq me sukses nga autobusi", "success");
        })
        .catch((error) => {
          this.$notify({
            title: "Gabim",
            text: "Gabim gjatë heqjes së caktimit të shoferit nga autobusi",
            type: 'error'
          });
          console.log(error);
          this.$swal("Gabim", error.response.data.message, "error");
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    closeDriverDialog() {
      this.driversDialog = false;
      this.loadingDrivers = false;
      this.availableDrivers = [];
    },
  },
};
</script>
